.list-portfolio{
	width: 100%;
	display: inline-block;
	position: relative;

	&:after {
	    border-top: 45px solid #FFFFFF;
	    border-left: 40px solid transparent;
	    border-right: 40px solid transparent;
	    bottom: -40px;
	    content: "";
	    position: absolute;
	    left: 49%;
	    margin-left: -21px;
	    width: 0;
	    height: 0;
	    z-index: 99;
	}

	.image-portfolio {
		background: url("../images/mackbook.png") no-repeat;
		display: block;
		width: 350px;
		height: 200px;
		margin: 30px auto;
		padding: 17px 44px 20px 44px;
		background-size: contain;
		position: relative;
		overflow: hidden;

	    .box-image{
	    	position: absolute;
		    margin: auto;
		    width: 73%;
		    height: 81%;
		    overflow: hidden;
			transition: all 6s ease-out 0s;
			-webkit-transition: all 6s ease-out 0s;
			-moz-transition: all 6s ease-out 0s;
			-ms-transition: all 6s ease-out 0s;
			-o-transition: all 6s ease-out 0s;

		    &:hover{
		    	overflow: hidden;
		    	content: "";
		    }

	    	img {
	    		position: absolute;
			    width: 100%;
			    height: auto;
			    transition: top 6s ease-out 0s;
			}
	    }
	}
}