// COLOR
.color-white{
	color: $color-white;
}

.color-black{
	color: $color-black;
}

.color-orange{
	color: $color-apecsa-orange;
}

.color-red-pink{
	color: $color-apecsa-red-pink;
}

.color-red-light{
	color: $color-apecsa-red-light;
}

.color-gray-dark{
	color: $color-apecsa-gray-dark;
}

.color-gray-light{
	color: $color-apecsa-gray-light;
}


// BACKGROUND
.bg-white{
	background: $color-white;
}

.bg-black{
	background: $color-black;
}

.bg-section{
	background: $color-section;
}

.bg-orange{
	background: $color-apecsa-orange;
}

.bg-red-pink{
	background: $color-apecsa-red-pink;
}

.bg-red-light{
	background: $color-apecsa-red-light;
}

.bg-gray-dark{
	background: $color-apecsa-gray-dark;
}

.bg-gray-light{
	background: $color-apecsa-gray-light;
}

.bg-yellow{
	background: #fece09;
}

.bg-orange-light{
	background: #ec9a18;
}