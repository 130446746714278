@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin transition($transition){
  -webkit-transition: all $transition ease-in-out;
     -moz-transition: all $transition ease-in-out;
       -o-transition: all $transition ease-in-out;
          transition: all $transition ease-in-out;
}

@mixin blur($blur){
	-webkit-filter: blur($blur);
		-moz-filter: blur($blur);
			-o-filter: blur($blur);
				-ms-filter: blur($blur);
					filter: blur($blur);
}