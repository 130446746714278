.footer-wrapper{
	background: #222222;
	text-align: center;
	padding: 70px 0px;
	margin-top: -5px;
	position: relative;

	.box-footer{
		margin: auto;
		display: inline-block;
		z-index: 999;

		.image{
			margin-bottom: 25px;

			img{

			}
		}

		.social-media{
			margin-bottom: 25px;
			width: 100%;
			display: inline-block;

			a{
				margin-right: 20px;
				@include border-radius(50%);

				&.facebook{
					background: #3B5998;
					color: $color-white;

					&:hover{
						background: $color-white;
						color: $color-black;
					}
				}

				&.twitter{
					background: #1DA1F2;
					color: $color-white;

					&:hover{
						background: $color-white;
						color: $color-black;
					}
				}

				&.linkedin{
					background: #1884BB;
					color: $color-white;

					&:hover{
						background: $color-white;
						color: $color-black;
					}
				}
				
				&.instagram{
					background: #1884BC;
					color: $color-white;

					&:hover{
						background: $color-white;
						color: $color-black;
					}
				}

				&:last-child{
					margin-right: 0px;
				}
			}
		}

		.corpyright{
			margin-bottom: 20px;

			span{
				color: $color-white;
				letter-spacing: 2px;
			}
		}
	}
}

.diagonal-shadow::before, .diagonal-shadow::after {
    top: 0;
    left: -25%;
    z-index: 6;
    width: 150%;
    height: 25%;
    background: inherit;
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

.diagonal-shadow::before, .diagonal-shadow::after {
    position: absolute;
    content: '';
    pointer-events: none;
}

.diagonal-shadow::before {
    height: 25%;
    background: $color-apecsa-red-pink;
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
    -webkit-transform-origin: 3% 0;
    transform-origin: 3% 0;
}
