.introduce-wrapper{
	display: inline-block;
	width: 100%;
	position: relative;
	
	&.contact-us{
		margin-top: -5px;
	}

	&.portfolio{
		margin-top: -5px;
	}
}