html {
	font-family: open-sans-regular;
    width: 100%;
    margin: 0;
    padding: 0;
}

a{
	text-decoration: none;
	cursor: pointer;
    @include transition(0.4s);
}

section{
    padding: 100px 0px;
    background: #f1f1f1;

    .box-title-desc{
        text-align: center;
        width: 100%;
        display: inline-block;

        .title{
            text-decoration: none;
            text-transform: capitalize;
            letter-spacing: 2px;
            font-family: open-sans-bold;
            line-height:0.1em; 
            margin:0px 0 40px;

            &.bdr-btm-gray{
                border-bottom: 1px solid #cecece; 
            }

            &.bdr-btm-orange{
                border-bottom: 1px solid $color-apecsa-orange; 
            }

            span{
                font-size: 25px;
                padding: 0 10px;
            }
        }

        .desc{
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 2px;
            margin-bottom: 30px;
            font-family: source-sans-pro-regular;
        }
    }
}

.apecsaos-wrapper{
    overflow: hidden;
}

.wow {
   visibility: hidden;
}


// BACK TO TOP
#back-to-top {
    position: fixed;
    bottom: 100px;
    right: 40px;
    width: 37px;
    height: 37px;
    text-align: center;
    line-height: 30px;
    background: #961f4e;
    color: #FFFFFF;
    cursor: pointer;
    border: 1px solid #961f4e;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    opacity: 0;
    font-size: 20px;
    padding: 0px;
    @include transition(0.4s);

    &:hover {
        background: #FFFFFF;
        color: #3a536d;
        @include transition(0.4s);
    }

    &.show {
        opacity: 1;
    }
}

// SLIDER
.slider-apecsa{
    width: 100%;
    display: inline-block;
    margin-bottom: -5px;
}

// PARALLAX
.parallax-apecsa{
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &:before {
        position: absolute;
        display: block;
        top:0;
        right:0;
        width: 100%;
        height: 100%;
        bottom: 0;
        z-index: 0;
        background : url(../images/sub_header/header2.jpg) repeat;
    }
}

.bg-img-1{
    background-image: url(../images/sub_header/header2.jpg);
    min-height: 100%;

    &:before{
        background: rgba(0, 0, 0, 0.43);
    }
}

.bg-img-2{
    background-image: url(../images/sub_header/header2.jpg);
    min-height: 100%;

    &:before{
        background: rgba(0, 0, 0, 0.43);
    }
}

.bg-service{
    background-image: url(../images/sub_header/header2.jpg);
    min-height: 100%;

    &:before{
        background: rgba(0, 0, 0, 0.43);
    }
}

.bg-portfolio{
    background-image: url(../images/sub_header/header2.jpg);
    min-height: 100%;

    &:before{
        background: rgba(0, 0, 0, 0.43);
    }
}

.bg-overlay-full{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.54);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

// MAPS GOOGLE
.maps-contact{
    margin-top: -70px;
}