// OPEN SANS
@font-face {
  font-family: open-sans-bold;
  src: url('../fonts/open-sans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: open-sans-bold-italic;
  src: url('../fonts/open-sans/OpenSans-BoldItalic.ttf');
}

@font-face {
  font-family: open-sans-extra-bold;
  src: url('../fonts/open-sans/OpenSans-ExtraBold.ttf');
}

@font-face {
  font-family: open-sans-extra-bold-italic;
  src: url('../fonts/open-sans/OpenSans-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: open-sans-italic;
  src: url('../fonts/open-sans/OpenSans-Italic.ttf');
}

@font-face {
  font-family: open-sans-light;
  src: url('../fonts/open-sans/OpenSans-Light.ttf');
}

@font-face {
  font-family: open-sans-light-italic;
  src: url('../fonts/open-sans/OpenSans-LightItalic.ttf');
}

@font-face {
  font-family: open-sans-regular;
  src: url('../fonts/open-sans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: open-sans-semibold;
  src: url('../fonts/open-sans/OpenSans-Semibold.ttf');
}

@font-face {
  font-family: open-sans-semibold-italic;
  src: url('../fonts/open-sans/OpenSans-SemiboldItalic.ttf');
}

// SOURCE SAN PRO
@font-face {
  font-family: source-sans-pro-black;
  src: url('../fonts/source-sans-pro/SourceSansPro-Black.otf');
}

@font-face {
  font-family: source-sans-pro-blacklt;
  src: url('../fonts/source-sans-pro/SourceSansPro-Blacklt.otf');
}

@font-face {
  font-family: source-sans-pro-bold;
  src: url('../fonts/source-sans-pro/SourceSansPro-Bold.otf');
}

@font-face {
  font-family: source-sans-pro-boldlt;
  src: url('../fonts/source-sans-pro/SourceSansPro-Boldlt.otf');
}

@font-face {
  font-family: source-sans-pro-light;
  src: url('../fonts/source-sans-pro/SourceSansPro-Light.otf');
}

@font-face {
  font-family: source-sans-pro-lightlt;
  src: url('../fonts/source-sans-pro/SourceSansPro-Lightlt.otf');
}

@font-face {
  font-family: source-sans-pro-regular;
  src: url('../fonts/source-sans-pro/SourceSansPro-Regular.otf');
}

@font-face {
  font-family: source-sans-pro-semibold;
  src: url('../fonts/source-sans-pro/SourceSansPro-Semibold.otf');
}