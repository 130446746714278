.bussiness-process{
	width: 100%;
	display: inline-block;
	margin-bottom: -5px;

	.stepwizard-row {
		display: table-row;

		&:before {
			top: 35px;
			bottom: 0;
			position: absolute;
			content: " ";
			width: 100%;
			height: 1px;
			background-color: #CCCCCC;
			z-order: 0;

		}
	}

	.stepwizard {
		display: table;     
		width: 100%;
		position: relative;
		margin-top: 30px;
	}

	.stepwizard-step{
		display: table-cell;
		text-align: center;
		position: relative;

		button[disabled] {
			opacity: 1 !important;
			filter: alpha(opacity=100) !important;
		}

		p {
			margin-top: 10px;    
		}
	}

	.btn-circle {
		width: 70px;
		height: 70px;
		text-align: center;
		padding: 6px 0;
		font-size: 12px;
		line-height: 1.428571429;
		@include border-radius(50%);

		i{
			font-size: 25px;
		}
	}
}

.timeline {
	list-style: none;
	padding: 20px 0 20px;
	position: relative;
}

.timeline:before {
	top: 0;
	bottom: 0;
	position: absolute;
	content: " ";
	width: 2px;
	background: rgba(238,238,238,1);
	background: -moz-linear-gradient(top, rgba(238,238,238,1) 0%, rgba(238,238,238,1) 0%, rgba(238,238,238,1) 86%, rgba(255,255,255,1) 92%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(238,238,238,1)), color-stop(0%, rgba(238,238,238,1)), color-stop(86%, rgba(238,238,238,1)), color-stop(92%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
	background: -webkit-linear-gradient(top, rgba(238,238,238,1) 0%, rgba(238,238,238,1) 0%, rgba(238,238,238,1) 86%, rgba(255,255,255,1) 92%, rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(top, rgba(238,238,238,1) 0%, rgba(238,238,238,1) 0%, rgba(238,238,238,1) 86%, rgba(255,255,255,1) 92%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(238,238,238,1) 0%, rgba(238,238,238,1) 0%, rgba(238,238,238,1) 86%, rgba(255,255,255,1) 92%, rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom, rgba(238,238,238,1) 0%, rgba(238,238,238,1) 0%, rgba(238,238,238,1) 86%, rgba(255,255,255,1) 92%, rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0 );
	left: 25px;
	margin-right: -1.5px;
}

.timeline > li {
	margin-bottom: 20px;
	position: relative;
}

.timeline > li:before,
.timeline > li:after {
	content: " ";
	display: table;
}

.timeline > li:after {
	clear: both;
}

.timeline > li:before,
.timeline > li:after {
	content: " ";
	display: table;
}

.timeline > li:after {
	clear: both;
}

.timeline > li > .timeline-panel {
	width: calc( 100% - 75px );
	float: right;
	border: 1px solid #d4d4d4;
	border-radius: 2px;
	padding: 20px;
	position: relative;
	-webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}

.timeline > li > .timeline-panel:before {
	position: absolute;
	top: 26px;
	left: -15px;
	display: inline-block;
	border-top: 15px solid transparent;
	border-right: 15px solid #ccc;
	border-left: 0 solid #ccc;
	border-bottom: 15px solid transparent;
	content: " ";
}

.timeline > li > .timeline-panel:after {
	position: absolute;
	top: 27px;
	left: -14px;
	display: inline-block;
	border-top: 14px solid transparent;
	border-right: 14px solid #fff;
	border-left: 0 solid #fff;
	border-bottom: 14px solid transparent;
	content: " ";
}

.timeline > li > .timeline-badge {
	color: #fff;
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 1.4em;
	text-align: center;
	position: absolute;
	top: 16px;
	left: 0px;
	margin-right: -25px;
	background-color: $color-apecsa-orange;
	z-index: 100;
	border-top-right-radius: 50%;
	border-top-left-radius: 50%;
	border-bottom-right-radius: 50%;
	border-bottom-left-radius: 50%;
}
.timeline > li.timeline-inverted > .timeline-panel {
	float: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
	border-right-width: 0;
	border-left-width: 15px;
	right: -15px;
	left: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
	border-right-width: 0;
	border-left-width: 14px;
	right: -14px;
	left: auto;
}

.timeline-badge.primary {
	background-color: #2e6da4 !important;
}

.timeline-badge.success {
	background-color: #3f903f !important;
}

.timeline-badge.warning {
	background-color: #f0ad4e !important;
}

.timeline-badge.danger {
	background-color: #d9534f !important;
}

.timeline-badge.info {
	background-color: #5bc0de !important;
}

.timeline-title {
	margin-top: 0;
	color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
	margin-bottom: 0;
}

.timeline-body > p + p {
	margin-top: 5px;
}

.timeline-body {
	display: block;
}